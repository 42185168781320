export default {
  waterwatchApiAuth: {
    username: "ZmYyNTZlMWItYWE1ZC00YTFkLTkwYjQtZTQ4NGQ0NjJmM2Rl",
    password:
      "MTUyODQzMTIzMToxODQzNzkxMjMxOjg1ZGMxZmJhLTQ2OGItNDQ0Mi05ZjMxLTQyMTU1M2YwY2E0Mg=="
  },

  useDevApi: false,

  orgId: "f6acd480-4370-11e8-a8b8-178f6463caf7",
  orgName: "Waterwatch Demonstration"
};
